import styled from '@emotion/styled';
import { SurfaceColumn } from '../styled';
// TODO: remove
const EmptyBlock = styled(SurfaceColumn)(props => ({
    minWidth: 423,
    maxWidth: 423,
    maxHeight: 423,
    minHeight: 423,
    boxShadow: '0px 1px 3px 0px #E6E8EB',
}));
export default EmptyBlock;
