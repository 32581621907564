import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { useMemo, useState } from 'react';
import { BodyMedium } from '@src/components/styled/Typography';
import DatePeriodMenu from '@shared/ui/menus/DatePeriodMenu';
import { GeneralPortfolioInformationPeriodsPreset } from '@shared/ui/menus/DatePeriodMenu/presets';
import { AssetsCurve, CopiersCurve, MarginCurve, ProfitCurve, } from '@entities/portfolio';
import Tabs from '@shared/ui/navigation/Tabs';
import { ContainerColumn, ContainerRow } from '@src/components/styled';
import { PROFIT_DATA, MANAGER_ASSETS_DATA, MARGIN_DATA, COPIERS_DATA, } from './mocks';
import { getAbsValues, getCopiersValues, getManagerAssetsValues, getMarginValues, } from './utils';
const InfoGraphsContainer = styled(ContainerColumn)(props => ({
    height: 'auto',
    boxSizing: 'border-box',
    background: props.theme.palette.text.disabled,
    padding: props.theme.spacing_sizes.l,
    borderRadius: 12,
    gap: props.theme.spacing_sizes.xs * 2.5,
}));
const PeriodSelectContainer = styled.div();
const GraphsTabsContainer = styled(ContainerRow)(() => ({
    justifyContent: 'space-between',
    alignItems: 'center',
}));
var ProfitFilterOptions;
(function (ProfitFilterOptions) {
    ProfitFilterOptions["All"] = "ALL";
    ProfitFilterOptions["7D"] = "7_D";
    ProfitFilterOptions["30D"] = "30_D";
    ProfitFilterOptions["90D"] = "90_D";
    ProfitFilterOptions["180D"] = "180_D";
})(ProfitFilterOptions || (ProfitFilterOptions = {}));
var PortfolioInfoGraph;
(function (PortfolioInfoGraph) {
    PortfolioInfoGraph["Profit"] = "PROFIT";
    PortfolioInfoGraph["ManagerAssets"] = "MANAGER_ASSETS";
    PortfolioInfoGraph["FollowersAssets"] = "FOLLOWERS_ASSETS";
    PortfolioInfoGraph["Margin"] = "MARGIN";
    PortfolioInfoGraph["CopiersNumber"] = "COPIERS_NUMBER";
    PortfolioInfoGraph["Risk"] = "RISK";
})(PortfolioInfoGraph || (PortfolioInfoGraph = {}));
const PortfolioInfoGraphs = () => {
    const { t } = useTranslation();
    const [activeTab, setActiveTab] = useState(PortfolioInfoGraph.Profit);
    const [activeFilter, setActiveFilter] = useState(GeneralPortfolioInformationPeriodsPreset[0]);
    const handleFilterChange = (p) => {
        setActiveFilter(p);
    };
    const { assetsData, usedProfitData, marginData, copiersData, } = useMemo(() => {
        if (activeFilter.type === 'day') {
            switch (activeFilter.count) {
                case 180:
                    return {
                        usedProfitData: getAbsValues(PROFIT_DATA.slice(0, 180)),
                        assetsData: getManagerAssetsValues(MANAGER_ASSETS_DATA.slice(0, 180)),
                        marginData: getMarginValues(MARGIN_DATA.slice(0, 180)),
                        copiersData: getCopiersValues(COPIERS_DATA.slice(0, 180)),
                    };
                case 90:
                    return {
                        usedProfitData: getAbsValues(PROFIT_DATA.slice(0, 90)),
                        assetsData: getManagerAssetsValues(MANAGER_ASSETS_DATA.slice(0, 90)),
                        marginData: getMarginValues(MARGIN_DATA.slice(0, 90)),
                        copiersData: getCopiersValues(COPIERS_DATA.slice(0, 90)),
                    };
                case 30:
                    return {
                        usedProfitData: getAbsValues(PROFIT_DATA.slice(0, 30)),
                        assetsData: getManagerAssetsValues(MANAGER_ASSETS_DATA.slice(0, 30)),
                        marginData: getMarginValues(MARGIN_DATA.slice(0, 30)),
                        copiersData: getCopiersValues(COPIERS_DATA.slice(0, 30)),
                    };
                case 7:
                    return {
                        usedProfitData: getAbsValues(PROFIT_DATA).slice(0, 7),
                        assetsData: getManagerAssetsValues(MANAGER_ASSETS_DATA).slice(0, 7),
                        marginData: getMarginValues(MARGIN_DATA).slice(0, 7),
                        copiersData: getCopiersValues(COPIERS_DATA).slice(0, 7),
                    };
                default:
            }
        }
        return {
            usedProfitData: getAbsValues(PROFIT_DATA),
            assetsData: getManagerAssetsValues(MANAGER_ASSETS_DATA),
            marginData: getMarginValues(MARGIN_DATA),
            copiersData: getCopiersValues(COPIERS_DATA),
        };
    }, [activeFilter]);
    const TabComponent = useMemo(() => {
        switch (activeTab) {
            case PortfolioInfoGraph.Profit:
                return (_jsx(ProfitCurve, { data: usedProfitData }));
            case PortfolioInfoGraph.ManagerAssets:
                return _jsx(AssetsCurve, { data: assetsData, assetName: 'USDT' });
            case PortfolioInfoGraph.FollowersAssets:
                return _jsx(AssetsCurve, { data: assetsData, assetName: 'USDT' });
            case PortfolioInfoGraph.Margin:
                return _jsx(MarginCurve, { data: marginData });
            case PortfolioInfoGraph.CopiersNumber:
                return _jsx(CopiersCurve, { data: copiersData });
            case PortfolioInfoGraph.Risk:
            default:
                return null;
        }
    }, [activeTab, assetsData, usedProfitData]);
    return (_jsxs(InfoGraphsContainer, { children: [_jsxs(GraphsTabsContainer, { children: [_jsx(Tabs, { activeTab: activeTab, onChange: setActiveTab, variant: 'chart-tabs', tabs: [
                            {
                                label: (_jsx(BodyMedium, { children: t('pages.portfolios.general_portfolio_information.tabs.profit', { ns: 'common' }) })),
                                value: PortfolioInfoGraph.Profit,
                            },
                            {
                                label: (_jsx(BodyMedium, { children: t('pages.portfolios.general_portfolio_information.tabs.manager_assets', { ns: 'common' }) })),
                                value: PortfolioInfoGraph.ManagerAssets,
                            },
                            {
                                label: (_jsx(BodyMedium, { children: t('pages.portfolios.general_portfolio_information.tabs.followers_assets', { ns: 'common' }) })),
                                value: PortfolioInfoGraph.FollowersAssets,
                            },
                            {
                                label: (_jsx(BodyMedium, { children: t('pages.portfolios.general_portfolio_information.tabs.margin', { ns: 'common' }) })),
                                value: PortfolioInfoGraph.Margin,
                            },
                            {
                                label: (_jsx(BodyMedium, { children: t('pages.portfolios.general_portfolio_information.tabs.copiers_number', { ns: 'common' }) })),
                                value: PortfolioInfoGraph.CopiersNumber,
                            },
                            {
                                label: (_jsx(BodyMedium, { children: t('pages.portfolios.general_portfolio_information.tabs.risks', { ns: 'common' }) })),
                                value: PortfolioInfoGraph.Risk,
                            },
                        ] }), _jsx(PeriodSelectContainer, { children: _jsx(DatePeriodMenu, { periods: GeneralPortfolioInformationPeriodsPreset, currentPeriod: activeFilter, onPeriodChange: handleFilterChange }) })] }), TabComponent] }));
};
export default PortfolioInfoGraphs;
