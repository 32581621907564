const getAbsValues = (data) => {
    const firstPoint = data[data.length - 1][1];
    return data.map((element) => ([
        new Date(element[0]),
        ((firstPoint - element[1]) / firstPoint) * 100,
    ]));
};
const getManagerAssetsValues = (data) => data.map((element) => ([new Date(element[0]), element[1]]));
const getMarginValues = (data) => data.map((element) => ([new Date(element[0]), element[1]]));
const getCopiersValues = (data) => data.map((element) => ([new Date(element[0]), element[1]]));
export { getAbsValues, getManagerAssetsValues, getMarginValues, getCopiersValues, };
